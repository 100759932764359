.checkBox{
    cursor: pointer;
    input{
        width: 20px;
        height: 20px;
        appearance: none;
        position: relative;
        overflow: hidden;
        box-shadow: unset;
        cursor: pointer;
        &:checked{
            background: var(--marketplaceColor);
            &:after{
                content: '';
                position: absolute;
                width: 13px;
                height: 7px;
                border: 2px solid #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -65%) rotate(-45deg);
                border-top: 0;
                border-right: 0;
                cursor: pointer;
            }
        }
    }
}











.filterHeader {
  line-height: 24px;
  padding: 24px 0 15px; 
  /* border-bottom: 1px solid #e4e9ee; */
  .labelButton {
    outline: none;
    line-height: 24px;
    text-align: left;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 100%;
    .labelButtonContent {
      display: inline-block;
      width: 100%;
      .labelWrapper {
        display: inline-block;
        max-width: calc(100% - 16px);
        .label {
          font-weight: var(--fontWeightBold);
          color: #0b0f0e !important;
          margin-top: 0;
          margin-bottom: 12px;
          padding: 4px 0 2px 0;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .openSign {
        float: right;
      }
    }
  }
}

.filterLists{
    border-bottom: 1px solid #e4e9ee;
    padding-bottom: 24px;
    ul{
        margin: 0;
        li{
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            
            .textRoot{
                font-family: Sora;
                font-size: 16px;
                font-weight: 400;
                line-height: 25.6px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #818B9C;
                 
            }
            &:hover{
                input{
                    border-color: var(--marketplaceColor);
                }
                .textRoot{
                    color: #000000;
                }
            }
        &:not(:last-child){
            padding-bottom: 12px;  

        } 
        }
    }
}
