.wrapperSearch{
    border: 1px solid #FFFFFF40;
      border-radius: 18px;
      .searchinput{ 
        display: flex;
          background: #fff;
          border-radius: 16px;
          padding: 4px;
        
      }
      .formField{
        flex: 1;
        display: flex;
          align-items: center;
          position: relative;
          padding-left: 24px;
          input{
            border-radius: 16px;
            border: 0;
            box-shadow: unset;
           &::placeholder{
                color: #262BBA80;              
              }
              [type="date"]{
                &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator{
                    display: none;
                    -webkit-appearance: none;
                }
            }         
            
          }
          &:first-child{
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 24px;
                background: #262BBA;
                right: 0;
              }
          }
      }
      
    
    @media screen and (max-width:1199px){
        .searchinput{ 
            flex-direction: column;
            
          } 
          .formField{
            &:first-child:after {    
                width: 100%;
                height: 1px;
                bottom: 0;
              }
          }
    }  
    @media screen and (max-width:991px){
        .searchinput{ 
            flex-direction: row;            
          } 
          .formField{
            &:first-child:after {    
                content: '';
                position: absolute;
                width: 1px;
                height: 24px;
                background: #262BBA;
                right: 0;
                top: 15px;
              }
          }
    }

    @media screen and (max-width:575px){
        .searchinput{ 
            flex-direction: column;       
          } 
          .formField{
            input{
                height: 50px;
               }
            &:first-child:after {    
                display: none;
              }
          }
    }
    
      
     
      
     
  }