.wrapperSearch{
    border: 1px solid #FFFFFF40;
      border-radius: 18px;
      .searchinput{ 
        display: flex;
          background: #fff;
          border-radius: 16px;
          padding: 4px;
        
      }
      .formField{
        flex: 1;
        display: flex;
          align-items: center;
          position: relative;
          padding-left: 24px;
          form{
            width:100%;
          }
          input{
            border-radius: 16px;
            border: 0;
            box-shadow: unset;
            font-size: 16px;
           &::placeholder{ 
                color: #262BBA80;              
              }
              [type="date"]{
                &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator{
                    display: none;
                    -webkit-appearance: none;
                }
            }         
            
          }
         
      }
      
    
    @media screen and (max-width:1199px){
        /* .searchinput{ 
            flex-direction: column;
            
          }  */
       
    }  
    @media screen and (max-width:991px){
        .searchinput{ 
            flex-direction: row;            
          } 
        
    }

    @media screen and (max-width:575px){
        .searchinput{ 
            flex-direction: column;       
          } 
          .formField{
            input{
                height: 50px;
               }
            &:first-child:after {    
                display: none;
              }
          }
    }
    
      
     
      
     
  }